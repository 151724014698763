<template>
  <AppCard height="230px" :style="{ border: `1px solid ${borderColor}` }" class="bordered-container">
    <v-card-text class="pa-0">
      <div class="image-container pa-0">
        <template v-if="product.media && product.media.length > 0">
          <div class="centered-container pa-0" style="background-color: #ffff;">
            <v-img :aspect-ratio="1 / 1" :src="product.media[0].original_url">
              <v-icon v-if="product.is_featured" class="position-absolute top-0 end-0 ma-2" size="small"
                color="#fcd800">
                mdi:mdi-star
              </v-icon>
            </v-img>
          </div>
        </template>
        <template v-else>
          <div>
            <v-icon v-if="product.is_featured" class="position-absolute top-0 left-0 ma-2" size="small" color="#fcd800">
              mdi:mdi-star
            </v-icon>
          </div>
          <span>{{ shortName }}</span>
        </template>
      </div>

      <div class="d-flex flex-column pa-0 h-100">
        <div class="pa-1 flex-grow-1" :style="{ borderTop: `1px solid ${borderColor}` }">
          <span class="text">
            <StockAlert :product="product" />
            <template v-if="product.display_on_site === true">
              <v-tooltip location="right">
                <template v-slot:activator="{ props }">
                  <AppIcon class="text-medium-emphasis" name="hugeicons:shopping-bag-02" size="12px" v-bind="props" />
                </template>
                <span>Publicado no catálogo</span>
              </v-tooltip>
            </template>
            {{ product.name }}
          </span>
        </div>
        <div class="d-flex align-center pa-1">
          <div>
            <b> R$ {{ $format.decimal(product.product_variants[0]?.price) }} </b>
          </div>
        </div>
      </div>
    </v-card-text>
  </AppCard>
</template>

<script setup>
const props = defineProps({
  product: {
    type: Object,
    required: true,
  },
});

const isDark = computed(() => localStorage.getItem("app-theme") === "dark");

const shortName = computed(() => {
  if (!props.product.name) return "";
  return props.product.name.length <= 10 ? props.product.name : props.product.name.substring(0, 10);
});

const borderColor = computed(() => (isDark.value ? "#374151" : "#e5e7eb"));
</script>

<style scoped>
.image-container {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.centered-container {
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 8px;
}

.text {
  font-size: 12px;
  font-weight: 500;
  word-wrap: break-word;
  display: block;
  display: -webkit-box;
  line-height: 1.2em;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.bordered-container {
  border-radius: 8px;
  overflow: hidden;
  display: relative;
}
</style>