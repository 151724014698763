<template>
  <v-dialog max-width="500" v-model="dialog" persistent :fullscreen="$vuetify.display.mobile">
    <v-card>
      <v-card-title>
        <h4 class="font-weight-regular">Calcular preço de venda</h4>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <AppNumberField v-model="costPrice" label="Preço de custo" @input="updateValuesFromCost" />
          </v-col>
          <v-col cols="12">
            <AppNumberField v-model="markup" label="Markup (%)" @input="updateOriginalPriceFromMarkup" prefix="%" />
            <div class="d-flex align-center ga-2">
              <AppIcon name="hugeicons:help-circle" size="16" class="text-medium-emphasis" />
              <p class="text-caption text-medium-emphasis">Markup é a porcentagem adicionada ao custo para definir o
                preço final.</p>
            </div>
          </v-col>
          <v-col cols="12">
            <AppNumberField v-model="originalPrice" label="Preço de venda" @input="updateMarkupFromPrice" />
          </v-col>
          <v-col cols="12">
            <AppNumberField v-model="profitValue" label="Lucro (%)" @input="updateProfitValue" prefix="%" />
            <div class="d-flex align-center ga-2">
              <AppIcon name="hugeicons:help-circle" size="16" class="text-medium-emphasis" />
              <p class="text-caption text-medium-emphasis">Lucro é a porcentagem de ganho sobre o preço de venda.</p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <app-cancel-btn @click="dialog = false" />
        <app-confirm-btn @click="confirmPrice()" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
const props = defineProps({
  productVariant: Object,
});

const dialog = ref(false);

const markup = ref(0);

const profitValue = ref(0);

const costPrice = ref(0);

const originalPrice = ref(0);

const open = (cost, productVariantData) => {
  costPrice.value = cost;

  originalPrice.value = productVariantData.original_price || 0;

  markup.value = cost > 0 ? ((originalPrice.value - costPrice.value) / costPrice.value) * 100 : 0;

  profitValue.value = originalPrice.value > 0 ? ((originalPrice.value - costPrice.value) / originalPrice.value) * 100 : 0;

  dialog.value = true;
};

const updateValuesFromCost = () => {
  if (costPrice.value > 0) {
    markup.value = ((originalPrice.value - costPrice.value) / costPrice.value) * 100;

    profitValue.value = originalPrice.value > 0 ? ((originalPrice.value - costPrice.value) / originalPrice.value) * 100 : 0;
  } else {
    markup.value = 0;

    profitValue.value = 0;
  }
};

const updateOriginalPriceFromMarkup = () => {
  if (markup.value !== null && costPrice.value !== 0) {
    originalPrice.value = costPrice.value * (1 + markup.value / 100);

    profitValue.value = ((originalPrice.value - costPrice.value) / originalPrice.value) * 100;
  }
};

const updateMarkupFromPrice = () => {
  if (originalPrice.value !== null && costPrice.value !== 0) {
    markup.value = ((originalPrice.value / costPrice.value) - 1) * 100;

    profitValue.value = ((originalPrice.value - costPrice.value) / originalPrice.value) * 100;
  }
};

const updateProfitValue = () => {
  if (profitValue.value !== null && profitValue.value < 100) {
    originalPrice.value = costPrice.value / (1 - profitValue.value / 100);

    markup.value = ((originalPrice.value - costPrice.value) / costPrice.value) * 100;
  }
};

const confirmPrice = () => {
  props.productVariant.original_price = originalPrice.value;

  dialog.value = false;
};

defineExpose({
  open,
});
</script>

<style scoped></style>
