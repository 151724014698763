<template>
  <v-chip :color="statuses[status].color" class="text-capitalize">
    {{ statuses[status].text }}
  </v-chip>
</template>

<script setup>

const props = defineProps({
  status: {
    required: true,
  },
});

const statuses = {
  processed: {
    color: "primary",
    text: "Processado",
  },
  canceled: {
    color: "error",
    text: "Cancelado",
  },
};

</script>

<style>
</style>