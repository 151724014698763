<template>
  <div class="mb-2">
    <b>Dados do produto</b>
  </div>
  <v-row>
    <v-col class="order-md-first order-last" cols="12" md="8">
      <v-row>
        <v-col>
          <AppTextField v-model="form.name" label="Nome" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <CategorySearch v-model="form.category" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <SupplierSearch v-model="form.supplier" />
        </v-col>
      </v-row>


    </v-col>
    <v-col cols="12" md="4">
      <div class="w-100 d-flex flex-column align-center">
        <ProductCard height="200px" width="200px" elevation="1" :product="form" />
        <appBtn class="mt-2" color="primary" size="small" variant="outlined" @click="handleAddImages">
          Adicionar fotos
        </appBtn>
      </div>
    </v-col>
  </v-row>

  <div class="mb-2 mt-8">
    <b v-if="!hasVariants">Preço e estoque</b>
    <b v-if="hasVariants">Variações</b>
  </div>

  <template v-if="!hasVariants">
    <ProductVariantSection :product="form" :productVariant="form.product_variants[0]" :singleVariant="true" />
  </template>

  <template v-if="hasVariants">
    <ProductVariantList :product="form" @edit="handleEditProductVariant($event)" />
  </template>

  <v-row>
    <v-col cols="8" md="4" class="py-0">
      <AppSwitch v-model="form.control_stock" label="Controlar estoque" color="primary" />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="8" md="6">
      <AppSwitch v-model="form.display_on_site" label="Exibir no catálogo" color="primary" />
      <AppSwitch v-model="form.is_featured" label="Destacar produto" color="primary" />
    </v-col>
  </v-row>

  <v-row class="mb-2">
    <v-col>
      <AppBtn class="mr-2" color="primary" size="small" variant="outlined" @click="handleAddVariant">
        Adicionar variações
      </AppBtn>
    </v-col>
  </v-row>

  <div class="mb-2 mt-8">
    <b>Dados adicionais</b>
  </div>
  <v-row>
    <v-col cols="12">
      <AppTextarea v-model="form.description" label="Descrição" auto-grow rows="2" />
      <template v-if="$features.has('ai')">
        <AppBtn class="mt-1" size="small" color="primary" variant="text" @click="handleGenerateDescriptionWithAI()"
          :disabled="!canGenerateAiDescription">
          <Icon name="hugeicons:magic-wand-01" class="mr-2" size="18" />
          Gerar com I.A.
        </AppBtn>
      </template>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <AppNumberField label="Comissão" v-model="form.commission_rate" :reverse="false" suffix="%" />
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12" md="6">
      <UnitySearch v-model="form.unity" />
    </v-col>
    <v-col cols="12" md="6">
      <AppSwitch v-model="form.allow_fractional_sales" label="Permite vender fracionado" color="primary" />
    </v-col>
  </v-row>

  <v-row>
    <v-col cols="12">
      <AppTextarea v-model="form.notes" label="Observações" auto-grow rows="2" />
    </v-col>
  </v-row>

  <template v-if="$features.has('nfe')">
    <div class="mb-2 mt-8">
      <b>Dados Fiscais</b>
    </div>
    <v-row>
      <v-col>
        <OrigemMercadoriaSelect v-model="form.origem" />
      </v-col>
      <!-- <v-col>
        <ClassificacaoFiscalSelect v-model="form.classificacao_fiscal" />
      </v-col> -->
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <NcmField v-model="form.ncm" label="NCM" density="default" />
      </v-col>
      <v-col cols="12" md="6">
        <CestField v-model="form.cest" label="CEST" density="default" />
      </v-col>
    </v-row>
  </template>

  <v-row>
    <v-col class="py-0" cols="8" md="4">
      <AppSwitch v-model="form.status" label="Cadastro Ativo" color="primary" />
    </v-col>
  </v-row>

  <ProductImagesForm ref="productImagesForm" />
  <ProductVariantsForm ref="productVariantsForm" />
  <ProductVariantForm ref="productVariantForm" />
  <StockTransactionForm ref="stockTransactionForm" @store="getStockTransactions()" />
  <AiDescriptionForm ref="aiDescriptionForm" @store="setAiDescription($event)" />
</template>

<script setup>

const $acl = useAcl();

const $features = useFeatures();

const productForm = useProductFormStore();

const { form, isInsert } = storeToRefs(productForm);

const productVariantsForm = ref(null);

const productVariantForm = ref(null);

const productImagesForm = ref(null);

const stockTransactionForm = ref(null);

const aiDescriptionForm = ref(null);

const hasVariants = computed(() => form.value.product_variants.length > 1);

const handleAddVariant = () => {
  productVariantsForm.value.open();
};

const handleEditProductVariant = (index) => {
  productVariantForm.value.open(index);
};

const handleEditStock = (productVariant) => {
  stockTransactionForm.value.open(form.value.id, productVariant.id);
};

const handleAddImages = () => {
  productImagesForm.value.open();
};

const canGenerateAiDescription = computed(() => {
  return form.value.name && form.value.name.length > 5;
});

const handleGenerateDescriptionWithAI = () => {
  aiDescriptionForm.value.open(form.value.name);
};

const setAiDescription = (description) => {
  form.value.description = description;
};
</script>

<style lang="scss" scoped></style>