<template>
  <v-row v-if="!singleVariant">
    <v-col>
      <AppTextField v-model="productVariant.name" label="Nome" />
    </v-col>
  </v-row>
  <v-row>
    <v-col class="order-md-first order-last" cols="12" md="4">
      <AppNumberField v-model="productVariant.cost" label="Custo" />
    </v-col>
    <v-col class="order-md-second" cols="12" md="4">
      <AppNumberField v-model="productVariant.original_price" label="Preço de Venda" />
      <AppBtn @click="handleCalculateMarkup()" class="mt-0" size="small" color="primary" variant="text">
        <Icon name="hugeicons:calculate" class="mr-2" size="18" />
        Calcular preço de venda
      </AppBtn>
    </v-col>
    <v-col class="order-md-last" cols="12" md="4">
      <AppNumberField :acceptNull="true" v-model="productVariant.promotional_price" label="Preço Promocional" />
    </v-col>
  </v-row>
  <v-row>
    <v-col v-if="showBarcodeScanner" cols="12" md="4">
      <AppBarcodeScanner ref="appBarcodeScanner" :noLoop="true" @input="setGtin" :alert="true" />
    </v-col>
    <v-col cols="12" md="4">
      <AppTextField v-model="productVariant.gtin" label="Código de barras" @change="validateGtin('gtin')"
        :append-inner-icon="hasBarcodeDetector ? 'mdi:mdi-barcode-scan' : null" @click:append-inner="scanBarcodeScan"/>
    </v-col>

    <v-col cols="12" md="4" v-if="$features.has('balance_integration')">
      <AppMaskField v-model="productVariant.balance_code" label="Código Balança" mask="######"
        @change="validateBalanceCode()">
      </AppMaskField>

      <AppBtn @click="autoGenerateBalanceCode()" class="mt-0" size="small" color="primary" variant="text">
        <Icon name="hugeicons:weight-scale-01" class="mr-2" size="18" />
        {{ productVariant.generate_balance_code ? "Informar" : "Gerar" }} código
        de balança
      </AppBtn>
    </v-col>
    <v-col cols="12" md="4">
      <AppTextField v-model="productVariant.reference" label="Referência" />
    </v-col>
    <v-col cols="12" md="4" v-if="$features.has('expiration_date')">
      <AppTextField type="date" v-model="productVariant.expiration_date" label="Validade" />
    </v-col>
  </v-row>


  <v-row v-if="productGtin?.id">
    <v-col>
      <v-card density="compact" color="error" variant="tonal">
        <v-card-text>
          <div cols="12">
            <h3>O código de barras já está em uso.</h3>
          </div>

          <div class="d-flex justify-space-between">
            <div>
              <v-checkbox density="compact" hide-details v-model="productVariant.resolve_gtin"
                label="Utilizar nesse produto?" />
            </div>
            <div class="mt-3">
              <span @click="redirectToProduct(productGtin.id)" style="cursor: pointer">
                Acessar produto
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row v-if="productBalanceCode?.id">
    <v-col>
      <v-card density="compact" color="error" variant="tonal">
        <v-card-text>
          <div cols="12">
            <h3>O código de balança já está em uso.</h3>
          </div>

          <div class="d-flex justify-space-between">
            <div>
              <v-checkbox density="compact" hide-details v-model="productVariant.resolve_balance_code"
                label="Utilizar nesse produto?" />
            </div>
            <div class="mt-3">
              <span @click="redirectToProduct(productBalanceCode?.id)" style="cursor: pointer">
                Acessar produto
              </span>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <v-row>
    <template v-if="showInitialStock">
      <v-col cols="12" md="4">
        <ProductQuantityField v-model="productVariant.initial_stock" label="Informar estoque inicial" :product="product"
          :acceptNull="true" />
      </v-col>
    </template>
    <template v-if="form.control_stock">
      <v-col cols="12" md="4">
        <ProductQuantityField v-model="productVariant.stock_min" label="Alerta Estoque Mínimo" :product="product"
          :acceptNull="true" />
      </v-col>
    </template>
  </v-row>
  <v-row v-if="showStock">
    <v-col cols="12">
      Estoque atual: {{ $format.decimal(productVariant.stock) }}
    </v-col>
  </v-row>
  <CalculateMarkupDialog ref="calculateMarkupForm" :productVariant="productVariant" />
</template>

<script setup>

const $features = useFeatures();

const productForm = useProductFormStore();

const { form, isInsert } = storeToRefs(productForm);

const calculateMarkupForm = ref(null);

const handleCalculateMarkup = () => {
  calculateMarkupForm.value.open(prop.productVariant.cost, prop.productVariant);
};

const { $http } = useNuxtApp();

const productGtin = ref({});
const productBalanceCode = ref({});

const prop = defineProps({
  product: {},
  productVariant: {},
  singleVariant: {
    type: Boolean,
    default: false,
  },
});

const showInitialStock = computed(() => {
  return prop.productVariant.id == null && form.value.control_stock;
});

const showBarcodeScanner = ref(false);

const hasBarcodeDetector = computed(() => "BarcodeDetector" in window);

const scanBarcodeScan = () =>
  (showBarcodeScanner.value = !showBarcodeScanner.value);

const setGtin = (gtin) => {
  prop.productVariant.gtin = gtin;
  showBarcodeScanner.value = false;
};

const showStock = computed(() => {
  return (
    form.value.control_stock &&
    !isInsert.value &&
    prop.productVariant.id != null
  );
});

const redirectToProduct = (product_id) => {
  let url = `/produtos?product_id=${product_id}`;

  window.open(url, "_blank");
};

const validateGtin = () => {
  searchProduct("barcode", prop.productVariant.gtin)
    .then((product) => {
      if (hasDuplicateBarcode(product)) {
        productGtin.value = product;
        prop.productVariant.productGtin = {
          product_id: product.id,
          product_variant_id: product.product_variants[0].id,
        };
      }

      if (!hasDuplicateBarcode(product)) {
        productGtin.value = null;
        prop.productVariant.productGtin = null;
      }
      prop.productVariant.resolve_gtin = false;
    })
    .catch((error) => console.log(error));
};

const autoGenerateBalanceCode = async () => {
  await $http
    .$get("product/generate-balance-code")
    .then((response) => {
      prop.productVariant.balance_code = response.balanceCode;
    })
    .catch((error) => { });
};

const validateBalanceCode = () => {
  searchProduct("balance_code", prop.productVariant.balance_code)
    .then((product) => {
      if (hasDuplicateBarcode(product)) {
        productBalanceCode.value = product;
        prop.productVariant.productBalanceCode = {
          product_id: product.id,
          product_variant_id: product.product_variants[0].id,
        };
      }

      if (!hasDuplicateBarcode(product)) {
        productBalanceCode.value = null;
        prop.productVariant.productBalanceCode = null;
      }
      prop.productVariant.resolve_balance_code = false;
    })
    .catch((error) => console.log(error));
};

const searchProduct = async (type, value) => {
  return await new Promise((resolve, reject) => {
    $http
      .index("product/productByBarcode", { [type]: value, statuses: [1, 0] })
      .then((response) => resolve(response.product))
      .catch((error) => reject(error));
  });
};

const hasDuplicateBarcode = (product) => {
  return (
    product &&
    product.id != prop.product.id &&
    product.product_variants[0].id != prop.productVariant.id
  );
};
</script>

<style lang="scss" scoped></style>
