<template>
  <v-table hover class="rounded-lg">
    <thead>
      <tr>
        <th width="10%">Data</th>
        <th width="20%">Origem</th>
        <th width="5%" class="text-center">Tipo</th>
        <th width="5%" class="text-right">Quant.</th>
        <th width="5%" class="text-center">Status</th>
        <th width="1%" class="text-right"></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="item in stockTransactions.data">
        <tr style="cursor: pointer">

          <td width="15%">
            {{ $format.dateBrWeek(item.date) }}
          </td>
          <td width="15%">
            <template v-if="item.origin == 'custom'">
              <template v-if="item.quantity > 0">
                Entrada: {{ item.description }}
              </template>
              <template v-else> Saída : {{ item.description }} </template>
            </template>
            <template v-if="item.origin == 'sale'">
              <template v-if="item.quantity <= 0">
                Venda #{{ item.sale.number }}
              </template>
              <template v-else> Devolução #{{ item.sale.number }} </template>
            </template>
            <template v-if="item.origin == 'service_order'">
              O.S. #{{ item.service_order.number }}
            </template>
            <template v-if="item.origin == 'purchase'">
              {{ item.description }}
            </template>
          </td>
          <td class="text-right">
            <div class="align-center d-flex ga-1">
              <AppIcon :color="item.quantity < 0 ? 'error' : 'primary'" size="18"
                :name="item.quantity < 0 ? 'hugeicons:square-arrow-up-right' : 'hugeicons:square-arrow-down-left'" />
              {{ getType(item) }}
            </div>
          </td>
          <td width="10%" class="text-right">
            <span :class="getQuantityTextClass(item.quantity)">
              {{ item.quantity > 0 ? `${Math.abs(item.quantity)}` : `-${Math.abs(item.quantity)}` }}
            </span>
          </td>

          <td width="5%" class="text-center">
            <StockTransactionStatusChip size="small" :status="item.status" />
          </td>
          <td class="text-end">
            <AppIcon class="text-disabled" name="hugeicons:arrow-right-01" />
          </td>
        </tr>
      </template>
    </tbody>
  </v-table>
</template>

<script setup>
const props = defineProps({
  stockTransactions: {
    required: true,
  },
});

const getQuantityTextClass = (quantity) => {
  if (quantity > 0) {
    return "text-primary";
  }
  return "text-error";

}

const getText = (stockTransaction) => {
  if (stockTransaction.origin === "sale") {
    return `Venda #${stockTransaction.sale_id}`;
  }

  if (stockTransaction.origin === "purchase") {
    return `Compra #${stockTransaction.purchase_id}`;
  }

  if (stockTransaction.origin === "custom") {
    return "Lançamento manual";
  }
};

const getType = (stockTransaction) => {
  if (stockTransaction.quantity > 0) {
    return "Entrada";
  } else {
    return "Saída";
  }
};
</script>

<style lang="scss" scoped></style>